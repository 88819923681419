<template>
  <div>
    <div class="home">
      <!-- 头部 -->
      <div class="home-header">
        <headear></headear>
      </div>
    </div>

    <div class="homePage">
      <!-- 轮播图 -->
      <div class="homePage-banner">
        <div class="arrowIconContainer">
          <div class="arrowIcon" ref="arrowIcon">
            <a href="#homePage-introduction">
              <el-image
                style="width: 25px; height: auto"
                :src="require('@/assets/image/arrow.png')"
                fit="fill"
              ></el-image>
            </a>
          </div>
        </div>
        <swiper ref="mySwiper" class="mySwiper" :options="swiperOptions">
          <swiper-slide v-for="(item, index) in banner" :key="index">
            <el-image
              ref="bannerHeight"
              scroll-container
              :src="item.url"
              fit="fit"
            ></el-image>
          </swiper-slide>
          <div class="swiper-pagination" slot="pagination"></div>
          <div class="swiper-button-prev" slot="button-prev"></div>
          <div class="swiper-button-next" slot="button-next"></div>
        </swiper>
      </div>
      <!-- 简介 -->
      <div class="homePage-introduction" id="homePage-introduction">
        <div class="introduction-container">
          <div class="introduction-container-title">瑞智科技</div>
          <div class="introduction-container-desciption">
            <p>
              成都瑞智数联科技有限公司（简称：“瑞智科技”）成立于2020年，是一家通过利用移动互联网、大数据、AI人工智能、云服务等先进技术，集自主研发、信息技术咨询与推广、运营管理于一体的创新型科技企业。
            </p>
            <p>
              瑞智科技一直专注于为金融产业提供科技服务，目前已推出--财瑞通科技平台，致力于为资产管理机构、财富管理机构、投资顾问、 投资人提供综合性资产与财富管理SaaS技术服务。财瑞通平台通过对金融机构及从业者进行科技化赋能，通过从业务开放、能力开放、技术开放三方面携手合作伙伴向我们的客户提供最优质的服务和选择，并提供营销推广、产品运营、投后管理、资产评价、营销获客、资产配置、基金评价、团队管理、在线培训等科技服务。
            </p>
            <p>
              瑞智科技建立了专业的资产定价分析系统、基金评价体系、投资者行为分析、投资组合管理等基于机器学习算法的投资辅助系统，辅助金融机构和投资者识别投资机会，管理投资风险。
            </p>
            <p>
              目前瑞智科技已是华为的联运合作伙伴、华为云云商店服务商，财瑞通已通过华为全栈国产化鲲鹏架构兼容认证并与华为合作联运。
            <p />
            <p />
          </div>
          <div class="introduction-container-value">
            <div class="introductionItem">
              <div class="introductionItemNumber">400+</div>
              <div class="introductionIcon"></div>
              <div class="introductionItemText">已注册投顾数量</div>
            </div>
            <div class="introductionItem">
              <div class="introductionItemNumber">2000+</div>
              <div class="introductionIcon"></div>
              <div class="introductionItemText">累计客户服务数量</div>
            </div>
            <div class="introductionItem">
              <div class="introductionItemNumber">100亿+</div>
              <div class="introductionIcon"></div>
              <div class="introductionItemText">累计完成交易规模</div>
            </div>
          </div>
        </div>
      </div>
      <!-- 描述 -->
      <div class="homePage-desciption">
        <div class="desciptionLeft">
          <div class="desciptionTitle">降本增效 安全可靠</div>
          <div class="desciptionText">
            借助不断升级迭代的数字科技平台服务，成为客户提质、降本、增效、可持续发展壮大的引擎
          </div>
          <div class="desciptionText">
            自主研发的核心系统采用微服务架构，业务数据化，运营数字化的全方位解决方案；具备隐私保护、安全可信、高可靠、高弹性、简单易用等特点
          </div>
        </div>
        <div class="desciptioncenter">
          <div class="desciptioncenterText">
            <div>以科技</div>
            <div>赋能产业</div>
          </div>
        </div>
        <div class="desciptionRight">
          <div class="desciptionTitle">高效链接 服务产业</div>
          <div class="desciptionText">连通全产业链,高效链接财富端与资产端</div>
          <div class="desciptionText">
            通过对金融机构及从业者的科技化赋能，让资本与产业深入对接、高效流转，从而服务实体经济，推动产业创新
          </div>
        </div>
      </div>
    </div>
    <!-- 脚部 -->
    <div class="home-footer">
      <foot></foot>
    </div>
  </div>
</template>
<script>
import store from "@/store/index.js";
import headear from "@/views/common/header.vue";
import foot from "@/views/common/footer.vue";
export default {
  name: "homePage",
  store,
  data() {
    return {
      swiperOptions: {
        slidesPerView: 1, // 每页展示几条数据
        slidesPerGroup: 1, // 每屏滚动几条数据
        autoplay: true, // 是否自动播放
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        pagination: {
          el: ".swiper-pagination", //与slot="pagination"处 class 一致
          clickable: true, //轮播按钮支持点击
        },
        observer: true,
        observeParents: true,
      },
      bannerHeight: 0,
      banner: [
        {
          id: "1",
          url: "https://web.oss-cn-chengdu.aliyuncs.com/website/banner1.png",
        },
        {
          id: "2",
          url: "https://web.oss-cn-chengdu.aliyuncs.com/website/banner2.png",
        },
        {
          id: "3",
          url: "https://web.oss-cn-chengdu.aliyuncs.com/website/banner3.png",
        },
      ],
      timer: {},
    };
  },
  computed: {
    getLeftOffset() {
      return this.$store.getters["getLeftOffset"];
    },
    getBannerOpacity() {
      return this.$store.getters["getBannerOpacity"];
    },
  },
  methods: {
    move() {
      let car = document.getElementsByClassName("arrowIcon");
      car[0].style.top = this.$store.state.leftOffset + "%";
      car[0].style.opacity = this.$store.state.bannerOpacity;
      
      this.$store.state.leftOffset += 1;
      this.$store.state.bannerOpacity -= 0.3;
      if (this.$store.state.leftOffset > 10) {
        this.$store.state.leftOffset = 0;
        this.$store.state.bannerOpacity = 1;
      }
    },
    //  toContent(){
    //      document.body.scrollTop = document.documentElement.scrollTop = '50%'
    //  }
  },
  components: {
    headear,
    foot,
  },
  mounted() {
    this.timer = setInterval(() => {
      this.move();
    }, 1 * 150);
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
};
</script>
<style lang="scss" scoped>
@import "@/style/common.scss";
.homePage-desciption {
  background: url("https://web.oss-cn-chengdu.aliyuncs.com/website/cohesion.png")
    no-repeat;
  background-size: 100% 100%;
  width: 100%;
  height: 100%;
}
.homePage-banner {
  position: relative;
  .arrowIconContainer {
    position: absolute;
    z-index: 998;
    left: 49.3%;
    bottom: 3%;
    height: 28px;
    .arrowIcon {
      position: absolute;
      bottom: 1%;
      z-index: 998;
    }
  }
  .arrowIconContainer:hover {
    cursor: pointer;
  }
  ::v-deep .el-carousel__indicators--horizontal {
    bottom: 60px;
  }
}
::v-deep .el-image {
  overflow: auto;
}
.app-container {
  .swiper {
    width: 100%;

    & >>> .swiper-container {
      width: 100%;

      .swiper-slide {
        width: 100%;
        height: 0;
        padding-bottom: 28.1%;
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;

        /* Center slide text vertically */
        display: -webkit-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        -webkit-justify-content: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        -webkit-align-items: center;
        align-items: center;
      }

      .swiper-pagination {
        .swiper-pagination-bullet-active {
          background-color: #f29b76;
        }
      }
    }
  }
}
::v-deep .swiper-container-horizontal > .swiper-pagination-bullets {
  bottom: 55px;
}
</style>
