<template>
<div>
  <div class="headear">
      <div class="header-container">
           <div class="headerLogo"> 
                <el-image class="logoInput" style="width: 118px; height: auto" :src="require('@/assets/image/logo.png')" fit="fit" @click="goDomain"></el-image>
           </div>
           <div class="headerMenu">
                <el-menu :default-active="this.$route.path" router  class="el-menu-demo" mode="horizontal" >
                    <el-menu-item index="/">首页</el-menu-item>
                    <el-menu-item index="/productService">产品服务</el-menu-item>
                    <el-menu-item index="/dynamicInformation">资讯动态</el-menu-item>
                    <el-menu-item index="/marketCooperation">市场合作</el-menu-item>
                    <el-menu-item index="/aboutMe">关于我们</el-menu-item>
                </el-menu>
            </div>
      </div>
  </div>
   <!-- 悬浮框 -->
    <div class="suspensionBox">
      <div class="suspensionBox-container">
        <ul>
          <li>
            <div class="suspensionBoxItem publicWechatHover">
              <div><el-image style="width: 30px; height: auto"  :src="require('@/assets/image/wechat.png')" fit="fit"></el-image></div>
              <div>公众号</div>
            </div>
            <div class="publicWechat"><el-image  style="min-width: 100px; height: auto" src="https://web.oss-cn-chengdu.aliyuncs.com/website/qrCodeOfficial.jpg" fit="fill"></el-image></div>
          </li>
           <li>
            <div  class="suspensionBoxItem minAppWechatHover">
              <div><el-image style="width: 30px; height: auto"  :src="require('@/assets/image/xiaochenxu.png')" fit="fit"></el-image></div>
              <div>小程序</div>
            </div>
             <div class="minAppWechat"><el-image  style="min-width: 100px; height: auto" src="https://web.oss-cn-chengdu.aliyuncs.com/website/smallProgram.jpg" fit="fill"></el-image></div>
          </li>
           <li>
            <div  class="suspensionBoxItem appWechatHover">
             <div><el-image style="width: 30px; height: auto"  :src="require('@/assets/image/phone.png')" fit="fit"></el-image></div>
              <div>APP</div>
            </div>
            <div class="appWechat"><el-image  style="min-width: 100px; height: auto" src="https://web.oss-cn-chengdu.aliyuncs.com/website/appDownload.png" fit="fill"></el-image></div>
          </li>
        </ul>
      </div>
      <div class="suspensionBox-top">
        <ul>
          <li class="toTop" @click="clikcToTop">
            <div class="toTop-container">
                <div>
                  <i class="el-icon-top" style="font-size:20px"></i>
                </div>
                <div>顶部</div>
            </div>
           
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
import store from '@/store/index.js';
export default {
  name: "headear",
  store,
  data() {
    return {
         activeIndex: '1',
    };
  },
  methods:{
    clikcToTop(){
      document.body.scrollTop = document.documentElement.scrollTop = 0
    },
    handleSelect(key, keyPath) {
      //  this.$store.commit('setCurrentType',key);
      //  this.$store.commit('setActiveIndex',key);
       console.log(key,keyPath)
       this.$router.push({path:key})

    },
    goDomain(){
      this.$router.push({path:'/'})
      //  this.$store.commit('setCurrentType',1);
      //  this.$store.commit('setActiveIndex','1')
    }
  },
  computed:{
    getActiveIndex(){
      return this.$store.getters['getActiveIndex']
    }
  }
};

</script>
<style lang="scss" scoped>
@import '@/style/common.scss';
</style>