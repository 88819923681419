<template>
<div>
   <div class="home">
      <!-- 头部 -->
      <div class="home-header">
        <headear></headear>
      </div>
 </div>
  <div class="marketCooperation">
    <!-- 广告图片 -->
    <div class="cooperation-banner">
      <div class="cooperation-bannerText">
        <div class="cooperation-bannerTextTitle">{{ about.title }}</div>
        <!--                <div class="cooperation-bannerTextValue">{{about.value}}</div>-->
      </div>
    </div>
    <!-- tabs切换 -->
    <div class="aboutMeTabs" v-if="activeNamecol<0">
      <div class="industryNews" v-loading="loading">
        <div class="industryNewsItem" v-for="(item,index) of listTable" :key="index">
          <div class="industryNewsItemImage" >
            <el-image style="width: 378px; height:auto" :src="item.cover[0].url" fit="fit"></el-image>
          </div>
          <div class="industryNewsItemText">
            <div class="industryNewsItemTextTitle" @click="showDetail(index)">{{item.title}}</div>
            <div class="industryNewsItemTextDesciption">
              <div  v-html="fontNumber(item.content)" ></div>
            </div>
            <div class="industryNewsItemTextDate">
              <div>{{ item.createTime == null ? "2020-2-1" : item.createTime }}</div>
              <div>新闻来源：{{ item.source == null ||item.source == '' ? '平台' : item.source }}</div>
            </div>
          </div>
        </div>
        <div style="text-align:right">
           <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="search.pageCurrent" :page-sizes="[5, 10, 15, 20]" :page-size="search.pageSize" layout="total, sizes, prev, pager, next, jumper" :total="total"></el-pagination>
        </div>
      </div>
    </div>
    <div class="aboutMeTabs" style=" width: 100%;position: relative;
    top: -70px;
    min-width: 500px;
    max-width: 1920px;">
      <div class="newsDetail" v-if="activeNamecol>=0">
        <div class="newsDetailItem">
          <div class="activeCol">

            <div class="newsDetailBreadcrumb" style="color: #999999">
              <span class="homeImg"></span>
              <span>当前位置 : &nbsp;</span>
              <span>资讯动态 > </span>
              <span>{{ newsDetailItem.title }}</span>
            </div>

            <div class="newsDetailTitle" style="  padding: 30px 5% 10px 5%;  font-size: 36px; font-weight: bold;">{{ newsDetailItem.title }}
            </div>
            <div class="createdBy"  style=" padding: 0 5%; font-size: 14px;font-weight: 400;color: #666666;line-height: 26px;">
              {{ newsDetailItem.source }} &nbsp;&nbsp;{{ newsDetailItem.createTime }}
            </div>
            <div class="newsDetailTextDesciption" v-html="newsDetailItem.content" style="padding: 5%;"></div>
            <div class="newsDetailFooter" style="padding: 5%">
              <div class="lastCol" @click="toLast">上一条 &nbsp;<span class="lastNext">{{ lastNews.title ? lastNews.title : '无'}}</span></div>
              <div class="nextCol" @click="toNext">下一条 &nbsp;<span class="lastNext">{{ nextNews.title ? nextNews.title : '无'}}</span></div>
              <div class="backToList" style="float: right; color: #00A0DE" @click="backlist">返回列表</div>
            </div>
          </div>


        </div>
      </div>
    </div>
  </div>
   <!-- 脚部 -->
      <div class="home-footer">
        <foot></foot>
      </div>
</div>
</template>
<script>
import api from '@/api/api.js'
import headear from "@/views/common/header.vue";
import foot from "@/views/common/footer.vue";
export default {
  name: "marketCooperation",
  data() {
    return {
      loading:false,
      search:{
        pageCurrent:1,
        pageSize:5
      },
      total:0,
      current:1,
      listTable: [],
      activeNamecol: 0,
      newsDetailItem: {},
      lastIndex: -1,
      lastNews: {},
      nextIndex: -1,
      nextNews: {},
      // activeInformation:"first",
      about: {
        title: "行业资讯 前沿分享",
        value: "Industry information cutting-edge sharing"
      },
      ruleForm: {
        name: '',
        desc: ''
      },
      rules: {
        name: [
          {required: true, message: '请输入活动名称', trigger: 'blur'},
          {min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur'}
        ],
        desc: [
          {required: true, message: '请填写活动形式', trigger: 'blur'}
        ]
      }

    }
  },
  methods: {
    fontNumber (date) {
      let reTag = /<img(?:.|\s)*?>/g;
      let length = date.length
      if (length > 250) {
        var str = ''
        str = date.substring(0, 250) + '......';
        str = str.replace(reTag,'')
        return str
      } else {
        date = date.replace(reTag,'')
        return date
      }
    },
    handleClick(tab, event) {
      console.log(tab.name, event)
    },
    showDetail(index) {
      this.activeNamecol = index;
      console.log(index,'index')
      this.newsDetailItem = this.listTable[index]
      if (this.listTable[index - 1]) {
        this.lastIndex = index - 1
        this.lastNews = this.listTable[index - 1]
      } else {
        this.lastIndex = -1
        this.lastNews = {}
      }
      if (this.listTable[index + 1]) {
        this.nextIndex = index + 1
        this.nextNews = this.listTable[index + 1]
      } else {
        this.nextIndex = -1
        this.nextNews = {}
      }
      document.documentElement.scrollTop = 0
    },
    backlist() {
      this.activeNamecol = -1
      document.documentElement.scrollTop = 0
    },
    toLast() {
      this.showDetail(this.lastIndex);
    },
    toNext() {
      this.showDetail(this.nextIndex);
    },

    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          alert('submit!');
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    getNewsList() {
      let params = {
        size:this.search.pageSize,
        current:this.search.pageCurrent
      }
      this.loading = true;
      api.newsList(params).then(res => {
        this.loading = false;
        if (res.data.code == 0) {
          this.listTable = res.data.data.records;
          this.total = Number(res.data.data.total);
        }
      })
    },
    handleSizeChange(val){
      this.search.pageSize = val;
      this.search.pageCurrent = 1;
      this.getNewsList()
    },
    handleCurrentChange(val){
      this.search.pageCurrent = val;
      this.getNewsList()

    }
  },
  components:{
headear,
foot
  },
  computed: {
    getActiveInformation() {
      return this.$store.getters['getActiveInformation']
    }
  },
  created() {
    this.getNewsList()
    this.activeNamecol = -1
  }
}
</script>
<style lang="scss">
img{
  width: 100%;
}
</style>
<style lang="scss" scoped>
@import "@/style/common.scss";
.cooperation-banner {
  background: url('https://web.oss-cn-chengdu.aliyuncs.com/website/dynamic.png') no-repeat;
  background-size: 100% 100%;
  width: 100%;
  min-height: 350px;
  max-height:500px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.homeImg {
  background: url('../../assets/image/home.png') no-repeat;
  height: 29px;
  width: 29px;
  display: inline-flex;
  vertical-align: middle;
}

::v-deep .el-tabs--card > .el-tabs__header .el-tabs__nav {
  width: 100%;
  display: flex;
  justify-content: center;
}
.lastNext:hover{
  cursor: pointer;
  color: #437CD3;
}
.backToList:hover{
  cursor: pointer;
}
</style>