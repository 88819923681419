import http from '../utils/http';

const api = {
    //联系我们
    contactUs(params){
        return http.post(`/operate/website/contact/us`,params)
    },
    // 招聘信息
    recruitment(params){
        return http.get(`/operate/website/recruitment`,params)
    },
    // 新闻列表
    newsList(params){
        return http.get(`/operate/website/news`,params)
    },
   
   
}
export default api;