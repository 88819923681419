<template>
<div>
    <div class="home">
      <!-- 头部 -->
      <div class="home-header">
        <headear></headear>
      </div>
 </div>
    <div class="marketCooperation">
        <!-- 广告图片 -->
        <div class="cooperation-banner">
            <div class="cooperation-bannerText">
                <div class="cooperation-bannerTextTitle">{{about.title}}</div>
<!--                <div class="cooperation-bannerTextValue">{{about.value}}</div>-->
            </div>
        </div>
        <!-- tabs切换 -->
        <div class="aboutMeTabs">
             <el-tabs v-model="getActiveProduct" type="card" @tab-click="handleClick">
                <el-tab-pane label="服务对象" name="first">
                    <div class="serviceObject">
                        <div>
                            <div class="serviceObjectText">
                                <div class="serviceObjectTextTitle">财瑞通  —  一站式科技服务平台</div>
<!--                                <div class="serviceObjectTextEng"> one-stop technology service platform</div>-->
                            </div>
                            <div class="serviceObjectBanner">
                                <div class="serviceObjectBannerItem" >
                                    <div>
                                        <div class="objectBannerItemIcon"><el-image :src="require('@/assets/image/money.png')"></el-image></div>
                                        <div class="serviceObjectBannerItemTitle">
                                           <span>财富管理机构</span>
                                        </div>
                                        <div class="serviceObjectBannerItemText">独立家族办公室 </div>
                                        <div class="serviceObjectBannerItemText">独立基金销售公司</div>
                                        <div class="serviceObjectBannerItemText">三方财富机构</div>
                                        <div class="serviceObjectBannerItemText">券商财富中心</div>
                                        <div class="serviceObjectBannerItemText">保险代理销售公司</div>
                                    </div>
                                  
                                </div>
                                <div class="serviceObjectBannerItem" >
                                    <div>
                                        <div class="objectBannerItemIcon" style="background:#3373C8"><el-image :src="require('@/assets/image/house.png')"></el-image></div>
                                        <div class="serviceObjectBannerItemTitle">
                                            <span>资产管理机构</span>
                                        </div>
                                        <div class="serviceObjectBannerItemText">私募/公募基金管理人</div>
                                        <div class="serviceObjectBannerItemText">基金子公司</div>
                                        <div class="serviceObjectBannerItemText">银行理财子公司</div>
                                        <div class="serviceObjectBannerItemText">企业融资方</div>
                                        <div class="serviceObjectBannerItemText">保险资管公司</div>
                                        <div class="serviceObjectBannerItemText">独立资管团队/FA</div>
                                    </div>
                                   
                                </div>
                            </div>
                        </div>

                    </div>
                </el-tab-pane>
                <el-tab-pane label="服务内容" name="second">
                    <div class="serviceContent">
                        <div class="serviceContentItem tecPlatform">
                            <div>
                                 <el-image style="width: 100%; height: auto" :src="require('@/assets/image/serveContent1.png')" fit="contain"></el-image>
                            </div>
                            <div class="contentItemList">
                                <div class="serviceContentItemTitle">科技服务平台</div>
<!--                                <div class="serviceContentItemEng">Technology Service Platform</div>-->
                                <div class="serviceContentItemesciption">一站式科技服务，提供专业的客户管理、渠道管理、产品管理、营销管理、清算管理、数据分析等服务，为展业各关键环节提供系统支持；提供专属定制化服务</div>
                            </div>
                        </div>
                        <div class="serviceContentItem productChainService">
                            <div>
                                 <el-image style="width: 100%; height: auto" :src="require('@/assets/image/serveContent2.png')" fit="contain"></el-image>
                            </div>
                            <div class="contentItemList">
                                 <div class="serviceContentItemTitle">产品供应链服务</div>
<!--                                <div class="serviceContentItemEng">Product Supply Chain Service</div>-->
                                <div class="serviceContentItemesciption">丰富的产品服务，采用智慧投研系统引入多样化的证券基金类产品、安全保障类产品、专属定制化组合等专业服务；提供相关法律、法规、政策咨询</div>
                            </div>
                           
                        </div>
                        <div class="serviceContentItem assetAllocation">
                             <div>
                                 <el-image style="width: 100%; height: auto" :src="require('@/assets/image/serveContent3.png')" fit="contain"></el-image>
                            </div>
                            <div class="contentItemList">
                                <div class="serviceContentItemTitle">资产配置定制化</div>
<!--                                <div class="serviceContentItemEng">Asset configuration customization</div>-->
                                <div class="serviceContentItemesciption">
                                    利用自研的资产定价分析系统、基金评价体系、投资组合管理等基于机器学习算法的投资辅助系统，辅助金融机构和投资者识别投资机会，管理投资风险；通过科学的投资策略、严谨的业务模式和成熟的风控体系，针对多元化的场景，量身定制专属资产配置方案
                                </div>
                            </div>
                            
                        </div>
                        <div class="serviceContentItem integratedMarketing">
                             <div>
                                 <el-image style="width: 100%; height: auto" :src="require('@/assets/image/serveContent4.png')" fit="contain"></el-image>
                            </div>
                            <div class="contentItemList">
                                 <div class="serviceContentItemTitle">整合营销</div>
<!--                                <div class="serviceContentItemEng">Integrated Marketing</div>-->
                                <div class="serviceContentItemesciption">高效整合机构流量和三方平台流量，基于大数据技术进行全方位的用户分析，提供更精准的服务和管理工具，多种触达方式，提升经营效率，降低获客成本</div>
                            </div>
                           
                        </div>
                    </div>
                </el-tab-pane>
            </el-tabs>
        </div>
    </div>
     <!-- 脚部 -->
      <div class="home-footer">
        <foot></foot>
      </div>
    </div>
</template>
<script>
import store from '@/store/index.js'
import headear from "@/views/common/header.vue";
import foot from "@/views/common/footer.vue";
export default {
    name:"marketCooperation",
    store,
    data(){
        return{
            activeNamecol: '1',
            // activeProduct:"first",
            about:{
                title:"提供综合性资产与财富管理服务",
                value:"Provide comprehensive asset and wealth management services"
            },
             ruleForm: {
                name: '',
                desc: ''
            },
        rules: {
          name: [
            { required: true, message: '请输入活动名称', trigger: 'blur' },
            { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
          ],
          desc: [
            { required: true, message: '请填写活动形式', trigger: 'blur' }
          ]
        }

        }
    },
    components:{
        headear,
foot
    },
    computed:{
        getActiveProduct(){
            return this.$store.getters['getActiveProduct']
        }
    },
    methods:{
        handleClick(tab, event) {
            console.log(tab.name, event)
        },
        submitForm(formName) {
            this.$refs[formName].validate((valid) => {
            if (valid) {
                alert('submit!');
            } else {
                console.log('error submit!!');
                return false;
            }
            });
        },
        resetForm(formName) {
            this.$refs[formName].resetFields();
        }
    }
}
</script>
<style lang="scss" scoped>
@import "@/style/common.scss";
.cooperation-banner{
        background: url('https://web.oss-cn-chengdu.aliyuncs.com/website/serveBanner.png') no-repeat;
        background-size: 100% 100%;
        width: 100%;
        min-height: 350px;
        max-height:500px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
}
// ::v-deep .el-tabs__nav-scroll {
//     width: 16%;
//     margin: 0 auto;
// }
 ::v-deep .el-tabs--card>.el-tabs__header .el-tabs__nav{
        width: 100%;
        display: flex;
        justify-content: center;
  }

.serviceObject{
    // background: url('../../assets/image/productObject.png') no-repeat;
    background-size: 100% 100%;
    height: 100%;
    width: 100%;
}
.tecPlatform,
.productChainService,
.assetAllocation,
.integratedMarketing{
    background-size: 100% 100%;
    height: 100%;
    width: 100%;
}
::v-deep .el-tabs--card>.el-tabs__header .el-tabs__nav{
    border: none;
}
</style>