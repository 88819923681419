<template>
<div>
 <div class="home">
      <!-- 头部 -->
      <div class="home-header">
        <headear></headear>
      </div>
 </div>

    <div class="marketCooperation">
        <!-- 广告图片 -->
        <div class="cooperation-banner">
            <div class="cooperation-bannerText">
                <div class="cooperation-bannerTextTitle">坚持务实创新 合作互利共赢</div>
<!--                <div class="cooperation-bannerTextValue">Adhere to pragmatic innovation, cooperation, mutual benefit and win-win</div>-->
            </div>
        </div>
        <!-- 商务合作详情 -->
        <div class="cooperation-text">
            <div class="cooperation-text-container">
                <div  class="cooperation-text-containerTitle">商务合作</div>
                <div  class="cooperation-text-containerText">
                    数字开启未来，服务促进发展，瑞智科技坚持务实创新、开放合作、互利共赢。联合以财富管理为终身事业、有共同价值观的团队，共同打造平等互助、独立决策、优势资源共享的高端财富管理联盟服务平台。
                </div>
                <div class="cooperation-text-containerConcat">
                    <div class="text-containerConcat-tel"><i class="el-icon-phone" style="color:#fff;margin-right:5px;background:#4d96f4;border-radius:50%;padding:5px"></i>028-85028556</div>
                    <div class="text-containerConcat-email"><i class="el-icon-message"  style="color:#fff;margin-right:5px;background:#4d96f4;border-radius:50%;;padding:5px"></i>marketing@taotra.com</div>
                </div>
            </div>
        </div>
    </div>
     <!-- 脚部 -->
      <div class="home-footer">
        <foot></foot>
      </div>
    </div>
</template>
<script>
import headear from "@/views/common/header.vue";
import foot from "@/views/common/footer.vue";
export default {
    name:"marketCooperation",
    data(){
        return{

        }
    },
    components:{
        headear,
        foot
    }
}
</script>
<style lang="scss" scoped>
@import "@/style/common.scss";
.cooperation-banner{
        background: url('https://web.oss-cn-chengdu.aliyuncs.com/website/cooperation-banner.png') no-repeat;
        background-size: 100% 100%;
        width: 100%;
        min-height: 350px;
        max-height: 500px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
}
</style>