<template>
    <div class="foot">
        <div class="footer-container">
            <!-- 友情链接    &&    二维码-->
            <div class="linkQrCode">
                <div class="link">
                    <div class="linkItem">
                        <div class="linkItem-title">关于我们</div>
                        <div class="footerIcon"></div>
<!--                        <div class="linkItemForeign">ABOUT US</div>-->
                        <div class="linkItemValue" @click="eventFoot('companiesIntroduce')">企业介绍</div>
                        <div class="linkItemValue" @click="eventFoot('recruiting')">招贤纳士</div>
                        <div class="linkItemValue" @click="eventFoot('contactUs')">联系我们</div>
                    </div>
                    <div class="linkItem">
                        <div class="linkItem-title">产品服务</div>
                        <div class="footerIcon"></div>
<!--                        <div class="linkItemForeign">SERVICE</div>-->
                        <div class="linkItemValue" @click="eventFoot('serviceObject')">服务对象</div>
                        <div class="linkItemValue" @click="eventFoot('serviceContent')">服务内容</div>
                    </div>
                    <div class="linkItem">
                        <div class="linkItem-title">其他</div>
                            <div class="footerIcon"></div>
<!--                        <div class="linkItemForeign">OTHER</div>-->
                            <div class="linkItemValue" @click="eventFoot('dynamicInformation')">资讯动态</div>
                            <div class="linkItemValue" @click="eventFoot('marketCooperation')">市场合作</div>
                    </div>
                </div>
                <div class="qrCode">
                    <div class="qrCodeItem">
                        <div class="qrCodeItem-title">公众号</div>
                        <div>
                            <el-image  style="min-width: 55px;max-width:112px; height: auto" src="https://web.oss-cn-chengdu.aliyuncs.com/website/qrCodeOfficial.jpg" fit="fill"></el-image>
                        </div>
                    </div>
                    <div class="qrCodeItem">
                        <div  class="qrCodeItem-title">小程序</div>
                        <div>
                             <el-image  style="min-width: 55px;max-width:112px; height: auto" src="https://web.oss-cn-chengdu.aliyuncs.com/website/smallProgram.jpg" fit="fill"></el-image>
                        </div>
                    </div>
                    <div class="qrCodeItem">
                        <div  class="qrCodeItem-title">财瑞通APP</div>
                        <div>
                             <el-image  style="min-width: 55px;max-width:112px; height: auto" src="https://web.oss-cn-chengdu.aliyuncs.com/website/appDownload.png" fit="fill"></el-image>
                        </div>
                    </div>
                </div>
            </div>
            <!-- 公司信息 -->
            <div class="copyRight">
                <div class="copyRightContainer">
                    <div class="copyRightContainerLeft">
                         <div class="copyRightContainerLeftTel">全国服务热线:028-85028556</div>
                         <div>地址:四川省成都市双流区天府国际基金小镇投资服务中心3楼</div>
                    </div>
                    <div>
                        Copyright @ 2021 成都瑞智数联科技有限公司 备案号: <a href="https://beian.miit.gov.cn/#/Integrated/index" class="recordNum" target="_blank">蜀ICP备2021026273号</a> 
                    </div>
                    
                </div>

            </div>
        </div>
    </div>
</template>
<script>
import store from '@/store/index.js';
export default {
    name:"foot",
    store,
    data(){
        return {}
    },
    methods:{
        toPageTop(){
            document.body.scrollTop = document.documentElement.scrollTop = 0
        },
        eventFoot(val){
            switch(val){
                case "companiesIntroduce":
                    this.$router.push({path:'/aboutMe'})
                    this.$store.commit('setActiveName','first');
                    this.$store.commit('setAbout',{
                        title:"为客户创造价值 为社会创造财富",
                        value:"Create value for customers Create wealth for society"
                    })
                    this.toPageTop()
                    break;
                case "recruiting":
                    this.$router.push({path:'/aboutMe'})
                    this.$store.commit('setActiveName','second')
                    this.$store.commit('setAbout',{
                        title:"我们需要像你一样的人才",
                        value:"We need talents like you"
                    })
                    this.toPageTop()
                    break;
                case "contactUs":
                    this.$router.push({path:'/aboutMe'})
                    this.$store.commit('setActiveName','third');
                    this.$store.commit('setAbout',{
                       title:"为客户创造价值 为社会创造财富",
                       value:"Create value for customers Create wealth for society"
                    })
                    this.toPageTop()
                    break;
                case  "serviceObject":
                    this.$router.push({path:'/productService'})
                    this.$store.commit('setActiveProduct','first')
                    this.toPageTop()
                    break;
                case "serviceContent":
                    this.$router.push({path:'/productService'})
                    this.$store.commit('setActiveProduct','second')
                    this.toPageTop()
                    break;
                case "dynamicInformation":
                    this.$router.push({path:'/dynamicInformation'})
                    this.$store.commit('setActiveInformation','first')
                    this.toPageTop()
                    break;
                case "marketCooperation":
                      this.$router.push({path:'/marketCooperation'})
                    this.toPageTop()
                    break;
                default:
                    break;
            }
        }
    }
}
</script>
<style lang="scss" scoped>
@import '@/style/common.scss';
</style>