<template>
  <div>
    <div class="home">
      <!-- 头部 -->
      <div class="home-header">
        <headear></headear>
      </div>
 </div>
      <div class="marketCooperation">
        <!-- 广告图片 -->
        <div class="cooperation-banner">
          <div class="cooperation-bannerText">
            <div class="cooperation-bannerTextTitle">{{ getAbout.title }}</div>
            <!--                <div class="cooperation-bannerTextValue">{{getAbout.value}}</div>-->
          </div>
        </div>
        <!-- tabs切换 -->
        <div class="aboutMeTabs">
          <el-tabs v-model="getActiveName" type="card" @tab-click="handleClick">
            <el-tab-pane label="企业文化" name="first">
              <div class="aboutMeTabsItem aboutOne">
                <div>
                  <div class="aboutMeLab">
                    <div class="aboutMeLabTitle">愿景</div>
                    <!--                                <div class="aboutMeLabEeg">VISION</div>-->
                  </div>
                  <div class="aboutMeLabText">
                    <div>致力打造金融科技产业生态的重要参与者</div>
                    <div>推动金融科技产业生态圈健康可持续发展</div>
                  </div>
                </div>
              </div>
              <div class="aboutMeTabsItem aboutTwo">
                <div>
                  <div class="aboutMeLab">
                    <div class="aboutMeLabTitle">使命</div>
                    <!--                                <div class="aboutMeLabEeg">MISSION</div>-->
                  </div>
                  <div class="aboutMeLabText">
                    <div>为客户创造价值</div>
                    <div>为社会创造财富</div>
                  </div>
                </div>
              </div>
              <div class="aboutMeTabsItem aboutThree">
                <div>
                  <div class="aboutMeLab">
                    <div class="aboutMeLabTitle">价值观</div>
                    <!--                                <div class="aboutMeLabEeg">VALUES</div>-->
                  </div>
                  <div class="aboutMeLabChoose">
                    <div class="chooseItem">信任</div>
                    <div class="chooseItem">责任</div>
                    <div class="chooseItem">高效</div>
                    <div class="chooseItem">创新</div>
                    <div class="chooseItem">奉献</div>
                  </div>
                </div>
              </div>
            </el-tab-pane>
            <el-tab-pane label="招贤纳士" name="second">
              <div class="recruiting">
                <div class="recruiting-container">
                  <div style="padding: 0 20%">
                    <p class="recruiting-containerText">
                      成都瑞智数联科技有限公司（简称“瑞智科技”），汇集了众多从事技术研发、数据处理、
                    </p>
                    <p class="recruiting-containerText">
                      财富管理、资产管理、产品策划与运营、营销等多方面的优秀专业人才。
                    </p>
                    <p class="recruiting-containerText">
                      管理团队核心成员主要由来自国内外知名企业的高级管理人员所组成。
                    </p>
                    <p class="recruiting-containerText">
                      欢迎您加入成都瑞智数联科技有限公司，成为我们的伙伴，请将简历投递至HR@taotra.com。
                    </p>
                  </div>
                </div>
                <div class="recruiting-collapse">
                  <el-collapse v-model="activeNamecol" :accordion="true">
                    <el-collapse-item
                      :name="index"
                      v-for="(item, index) in listTable"
                      :key="index"
                    >
                      <div slot="title">
                        <div class="collapse-title">{{ item.name }} {{item.company ? `(${item.company})` : ''}}</div>
                        <div class="collapse-desciption">
                          <div class="collapseDesciptionItem">
                            工作地点：{{ item.base }}
                          </div>
                          <div class="collapseDesciptionItem">
                            学历要求：{{ item.education }}
                          </div>
                          <div class="collapseDesciptionItem">
                            工作经验：{{ item.workExperience }}年以上
                          </div>
                          <div class="collapseDesciptionItem">
                            招聘人数：{{ item.numbers }}个
                          </div>
                        </div>
                      </div>
                      <div>
                        <div>职位描述:</div>
                        <div>{{ item.jobDesc }}</div>
                      </div>
                      <div>
                        <div>任职要求:</div>
                        <div>{{ item.jobRequirement }}</div>
                      </div>
                    </el-collapse-item>
                  </el-collapse>
                </div>
              </div>
            </el-tab-pane>
            <el-tab-pane label="联系我们" name="third">
              <div class="concatMe">
                <div class="concatMeLocation">
                  <div class="concatMeLocationMap">
                    <div class="locationMapHere">
                      <div class="locationMapHereText">
                        <div class="locationMapHereTextTitle">成都</div>
                        <div class="locationMapHereTextAddress">
                          四川省成都市双流区天府国际基金小镇投资服务中心3楼
                        </div>
                        <i class="locationMapHereTextIcon"></i>
                      </div>
                      <div class="locationMapHereImage">
                        <el-image
                          style="width: 56px; height: 100%"
                          :src="require('@/assets/image/location.png')"
                          fit="fit"
                        ></el-image>
                      </div>
                    </div>
                  </div>
                  <div class="concatMeLocationTel">
                    <div class="concatMeLocationTelTitle">
                      成都瑞智数联科技有限公司
                    </div>
                    <div class="concatMeLocationTelTel">
                      <div class="tel"></div>
                      <div>电话：028-85028556</div>
                    </div>
                    <div class="concatMeLocationTelTel">
                      <div class="eamil"></div>
                      <div>邮箱：marketing@taotra.com</div>
                    </div>
                    <div class="concatMeLocationTelTel">
                      <div class="address"></div>
                      <div>地址：四川省成都市双流区天府国际基金小镇投资服务中心3楼</div>
                    </div>
                    <div class="concatMeLocationTelTel">
                      <div class="chrome"></div>
                      <div>网址：www.taotrade.cn</div>
                    </div>
                    <div class="concatMeLocationTelTel">
                      <div class="eamil"></div>
                      <div>邮编：610011</div>
                    </div>
                  </div>
                </div>
                <div class="concatMeForm">
                  <div class="concatMeForm-container">
                    <div class="concatMeForm-title">联系我们</div>
                    <!--  <div class="concatMeForm-eng">CONTACT US</div>-->
                    <div class="concatMeForm-text">
                      请详细填写下列表格，并尽量提供完整正确信息，我们将有专人为您服务
                    </div>
                    <div class="concatMeForm-form">
                      <el-form
                        :model="ruleForm"
                        :rules="rules"
                        ref="ruleForm"
                        label-width="100px"
                        class="demo-ruleForm"
                      >
                        <el-row>
                          <el-col :span="12">
                            <el-form-item label="您的姓名" prop="name">
                              <el-input v-model="ruleForm.name"></el-input>
                            </el-form-item>
                          </el-col>
                          <el-col :span="12">
                            <el-form-item label="您的邮箱" prop="email">
                              <el-input v-model="ruleForm.email"></el-input>
                            </el-form-item>
                          </el-col>
                        </el-row>
                        <el-row>
                          <el-col :span="12">
                            <el-form-item label="您的电话" prop="phone">
                              <el-input v-model="ruleForm.phone"></el-input>
                            </el-form-item>
                          </el-col>
                          <el-col :span="12">
                            <el-form-item label="您的职位" prop="job">
                              <el-input v-model="ruleForm.job"></el-input>
                            </el-form-item>
                          </el-col>
                        </el-row>
                        <el-row>
                          <el-col :span="24">
                            <el-form-item label="留言内容" prop="content">
                              <el-input
                                type="textarea"
                                v-model="ruleForm.content"
                                :autosize="{ minRows: 5, maxRows: 8 }"
                              ></el-input>
                            </el-form-item>
                          </el-col>
                        </el-row>
                        <el-form-item>
                          <el-button
                            type="primary"
                            @click="submitForm('ruleForm')"
                            style="width: 49%"
                            >提交</el-button
                          >
                          <el-button
                            @click="resetForm('ruleForm')"
                            style="width: 49%"
                            >重新填写</el-button
                          >
                        </el-form-item>
                      </el-form>
                    </div>
                  </div>
                </div>
              </div>
            </el-tab-pane>
          </el-tabs>
        </div>
      </div>
      <!-- 脚部 -->
      <div class="home-footer">
        <foot></foot>
      </div>
   
  </div>
</template>
<script>
import store from "@/store/index.js";
import api from "@/api/api.js";
import headear from "@/views/common/header.vue";
import foot from "@/views/common/footer.vue";
export default {
  name: "marketCooperation",
  store,
  data() {
    return {
      listTable: [],
      activeNamecol: "1",
      ruleForm: {
        name: "",
        email: "",
        phone: "",
        job: "",
        content: "",
      },
      rules: {
        name: [{ required: true, message: "当前为必填", trigger: "blur" }],
        email: [{ required: true, message: "当前为必填", trigger: "blur" }],
        phone: [{ required: true, message: "当前为必填", trigger: "blur" }],
        job: [{ required: true, message: "当前为必填", trigger: "blur" }],
        content: [{ required: true, message: "当前为必填", trigger: "blur" }],
      },
    };
  },

  methods: {
    // 获取招聘信息
    getRecruitment() {
      let params = {};
      api.recruitment(params).then((res) => {
        if (res.data.code == 0) {
          this.listTable = res.data.data.records;
        }
      });
    },
    handleClick(tab, event) {
      console.log(event);
      if (tab.name == "first") {
        let about = {
          title: "致力于打造金融科技生态的参与者",
          value: "Participants committed to building a fintech ecosystem",
        };
        this.$store.commit("setAbout", about);
      } else if (tab.name == "second") {
        let about = {
          title: "我们需要像你一样的人才",
          value: "We need talents like you",
        };
        this.$store.commit("setAbout", about);
      } else if (tab.name == "third") {
        let about = {
          title: "为客户创造价值 为社会创造财富",
          value: "Create value for customers Create wealth for society",
        };
        this.$store.commit("setAbout", about);
      }
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let params = this.ruleForm;
          api.contactUs(params).then((res) => {
            if (res.data.code == 0) {
              this.$message.success("提交成功");
              this.ruleForm = {
                name: "",
                email: "",
                phone: "",
                job: "",
                content: "",
              };
            }
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
  },
  computed: {
    getActiveName() {
      return this.$store.getters["getActiveName"];
    },
    getAbout() {
      return this.$store.getters["getAbout"];
    },
  },
  components:{
      headear,
      foot
  },
  created() {
    this.getRecruitment();
  },
};
</script>
<style lang="scss" scoped>

.cooperation-banner {
  background: url("https://web.oss-cn-chengdu.aliyuncs.com/website/aboutMe-banner.png")
    no-repeat;
  background-size: 100% 100%;
  width: 100%;
  min-height: 350px;
  max-height: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.aboutOne,
.aboutTwo,
.aboutThree {
  background-size: 100% 100%;
  width: 100%;
  height: 500px;
}
.aboutOne {
  background: url("https://web.oss-cn-chengdu.aliyuncs.com/website/aboutOne.png")
    no-repeat;
}
.aboutTwo {
  background: url("https://web.oss-cn-chengdu.aliyuncs.com/website/aboutTwo.png")
    no-repeat;
}
.aboutThree {
  background: url("https://web.oss-cn-chengdu.aliyuncs.com/website/aboutThree.png")
    no-repeat;
}
.concatMeLocationMap {
  background: url("https://web.oss-cn-chengdu.aliyuncs.com/website/map.png")
    no-repeat;
  background-size: 100% 100%;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  .locationMapHere {
    text-align: center;
    margin-left: 137px;
    margin-bottom: 80px;
    .locationMapHereText {
      background: #437cd3;
      padding: 5px 10px;
      text-align: left;
      border-radius: 5px;
      color: #fff;
      position: relative;
      .locationMapHereTextTitle {
        font-size: 24px;
        font-weight: bold;
      }
      .locationMapHereTextAddress {
        font-size: 16px;
        margin-top: 5px;
      }
      .locationMapHereTextIcon {
        width: 0px;
        height: 0px;
        border-left: 15px solid transparent;
        border-top: 15px solid #437cd3;
        border-right: 15px solid transparent;
        position: absolute;
        left: 45%;
        top: 100%;
      }
    }
    .locationMapHereImage {
      margin-top: 10px;
    }
  }
}
::v-deep .el-tabs--card > .el-tabs__header .el-tabs__nav {
  width: 100%;
  display: flex;
  justify-content: center;
}
.tel {
  background: url("../../assets/image/tel.png") no-repeat;
}
.eamil {
  background: url("../../assets/image/eamil.png") no-repeat;
}
.address {
  background: url("../../assets/image/address.png") no-repeat;
}
.chrome {
  background: url("../../assets/image/chrome.png") no-repeat;
}
.recruiting-container {
  background: url("../../assets/image/recruiting.png") no-repeat #f6f9ff;
}

.tel,
.address,
.chrome,
.eamil {
  background-size: 100% 100%;
  height: 32px;
  width: 32px;
  margin-right: 5px;
}
::v-deep .el-tabs__header {
  margin: 0;
}
::v-deep .el-tabs--card > .el-tabs__header .el-tabs__nav {
  border: none;
}
@import "@/style/common.scss";
</style>